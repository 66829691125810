import { apiLogin, apiClassInfo, apiStudentInfo, apiExamType, apiLevels, apiQZStudentInfo, apiPeriod } from '@/api/user'
import { getToken, setToken, removeToken, getTerm } from '@/utils/auth'
import { clearAll, getItem, setItem } from '@/utils/LsyLocalStorage'
import { jiami, jiemi } from '@/utils/MD5'

// initial state
// shape: [{ id, quantity }]
const getDefaultState = () => {
    return {
        token: getToken(),
        userInfo: getItem('userInfo') || [],
        classList: getItem('classList') || [],
        studentList: getItem('studentList') || [],
        examTypeList: getItem('examTypeList') || [],
        periodList: getItem('periodList') || [],
        className: '',
        paper: '',
        grade: '',
        IsGolBal: '',
        period: '',
        appLogin: false
    }

}

const state = getDefaultState()



// getters
const getters = {
    cartProducts: (state, getters, rootState) => {
        return state.items.map(({ id, quantity }) => {
            const product = rootState.products.all.find(product => product.id === id)
            return {
                id: product.id,
                title: product.title,
                price: product.price,
                quantity
            }
        })
    },

    cartTotalPrice: (state, getters) => {
        return getters.cartProducts.reduce((total, product) => {
            return total + product.price * product.quantity
        }, 0)
    }
}

// actions
const actions = {
    async login({ commit, state }, data) {
        clearAll();
        commit('RESET_STATE')
        return new Promise((resolve, reject) => {
            apiLogin(data).then(response => {
                var userInfo = response.data;
                let token = jiami('token' + userInfo.pk_Kid);
                setToken(token)
                commit('SET_USERINFO', userInfo)
                resolve(true);
            }).catch(error => {
                commit('SET_USERINFO', [])
                reject(error)
            })
        });
    },
    async qzfLogin({ commit, state }, data) {
        clearAll();
        commit('RESET_STATE')
        return new Promise((resolve, reject) => {
            let userInfo = [{ userId: data }];
            let token = jiami('token' + data);
            setToken(token)
            commit('SET_USERINFO', userInfo)
            resolve(true);
        });
    },
    async LoginOut({ commit, state }) {
        return new Promise((resolve, reject) => {
            clearAll();
            commit('RESET_STATE')
            resolve()
        });

    },

    async getClassList({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            apiClassInfo(state.userInfo[0], data.pk_Period, data.IsGolBal).then(response => {
                var list = response.data;
                commit('SET_CLASSLIST', list)
                resolve(list);
            }).catch(error => {
                commit('SET_CLASSLIST', [])
                reject(error)
            })
        });

    },

    async getExamTypeList({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            //console.log(state.examTypeList);
            //if (state.examTypeList.length > 0) {
            //    resolve(state.examTypeList);
            //} else {
            apiExamType(state.userInfo[0], data.pk_Period, data.IsGolBal).then(response => {
                var list = response.data;
                commit('SET_EXAMTYPELIST', list)
                resolve(list);
            }).catch(error => {
                commit('SET_EXAMTYPELIST', [])
                reject(error)
            })
            //}
        });
    },
    async getPeriodList({ commit, state }, IsGolBal) {
        return new Promise((resolve, reject) => {
            apiPeriod(IsGolBal).then(response => {
                var list = response.data;
                commit('SET_PERIODLIST', list)
                resolve(list);
            }).catch(error => {
                commit('SET_PERIODLIST', [])
                reject(error)
            })
        });
    },

    async getLevels({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            apiLevels(state.userInfo[0], data.pk_Period, data.ExamCode).then(response => {
                var list = response.data;
                resolve(list);
            }).catch(error => {
                reject(error)
            })
        });
    },

    async getStudentList({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            apiStudentInfo(state.userInfo[0], data.pk_Period, data.className, data.IsGolBal).then(response => {
                var list = response.data;
                resolve(list);
                commit('SET_STUDENTLIST', list)
            }).catch(error => {
                commit('SET_STUDENTLIST', [])
                reject(error)
            })
        });
    },

    async getQZStudentList({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            apiQZStudentInfo(data.TeacherId, data.beginDate, data.endDate, data.userName).then(response => {
                var list = response.data;
                resolve(list);
                commit('SET_STUDENTLIST', list)
            }).catch(error => {
                commit('SET_STUDENTLIST', [])
                reject(error)
            })
        });
    }

}

// mutations
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo;
        setItem('userInfo', userInfo);
    },
    SET_CLASSLIST: (state, classList) => {
        state.classList = classList;
        setItem('classList', classList);
    },
    SET_STUDENTLIST: (state, studentList) => {
        state.studentList = studentList;
        setItem('studentList', studentList);
    },
    SET_EXAMTYPELIST: (state, examTypeList) => {
        state.examTypeList = examTypeList;
        setItem('examTypeList', examTypeList);
    },
    SET_PERIODLIST: (state, periodList) => {
        state.periodList = periodList;
        setItem('periodList', periodList);
    },

    SET_CLASSNAME: (state, className) => {
        state.className = className;
    },

    SET_PAPER: (state, paper) => {
        state.paper = paper;
    },

    SET_GRADE: (state, grade) => {
        state.grade = grade;
    },
    SET_PERIOD: (state, period) => {
        state.period = period;
    },
    SET_ISGOLBAL: (state, IsGolBal) => {
        state.IsGolBal = IsGolBal;
    },
    SET_APPLOGIN: (state, appLogin) => {
        state.appLogin = appLogin
    },
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

    //addProductToCart({ state, commit }, product) {
    //    commit('setCheckoutStatus', null)
    //    if (product.inventory > 0) {
    //        const cartItem = state.items.find(item => item.id === product.id)
    //        if (!cartItem) {
    //            commit('pushProductToCart', { id: product.id })
    //        } else {
    //            commit('incrementItemQuantity', cartItem)
    //        }
    //        // remove 1 item from stock
    //        commit('products/decrementProductInventory', { id: product.id }, { root: true })
    //    }
    //}
    //const savedCartItems = [...state.items]
    //commit('setCheckoutStatus', null)
    //// empty cart
    //commit('setCartItems', { items: [] })
    //try {
    //    await shop.buyProducts(products)
    //    commit('setCheckoutStatus', 'successful')
    //} catch (e) {
    //    console.error(e)
    //    commit('setCheckoutStatus', 'failed')
    //    // rollback to the cart saved before sending the request
    //    commit('setCartItems', { items: savedCartItems })
    //}
