import Cookies from 'js-cookie'

const TokenKey = 'vue_admin_template_token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getTerm() {
  let term = new Array();
  let beginYear = 2020;
  let baseYear = new Date().getFullYear();
  const nowDate = new Date();
  if (nowDate < new Date(new Date().getFullYear() + '-02-01')) {
    baseYear = baseYear - 1;
  }

  if ((baseYear - 3) > beginYear) {
    beginYear = baseYear - 3;
  }

  let termType = '春';
  if (nowDate <= new Date((baseYear + 1) + '-01-31') && nowDate >= new Date(baseYear + '-08-01')) {
    termType = '秋';
  }
  for (var i = baseYear; i >= beginYear; i--) {
    term.push(
      {
        data: i + "年秋季（" + i + ".08.01 - " + (i + 1) + ".01.31）", key: i + "秋"
      }
    )
    term.push(
      {
        data: i + "年春季（" + i + ".02.01 - " + i + ".07.31）", key: i + "春"
      }
    )

    //if (i == baseYear) {
    //  if (termType == '秋') {
    //    term.push(
    //      {
    //        data: i + "年秋季（" + i + ".08.01 - " + (i + 1) + ".01.31）", key: i + "秋"
    //      }
    //    )
    //    term.push(
    //      {
    //        data: i + "年春季（" + i + ".02.01 - " + i + ".07.31）", key: i + "春"
    //      }
    //    )
    //  } else {
    //    term.push(
    //      {
    //        data: i + "年春季（" + i + ".02.01 - " + i + ".07.31）", key: i + "春"
    //      }
    //    )
    //  }
    //} else {
    //  term.push(
    //    {
    //      data: i + "年秋季（" + i + ".08.01 - " + (i + 1) + ".01.31）", key: i + "秋"
    //    }
    //  )
    //  term.push(
    //    {
    //      data: i + "年春季（" + i + ".02.01 - " + i + ".07.31）", key: i + "春"
    //    }
    //  )
    //}
  }
  return term;
}
