import { clearAll, getItem, setItem } from '@/utils/LsyLocalStorage'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
    name: '1111',
    type: getItem('appType') || 'dachuangguan',
    IsPhone: getItem('IsPhone') || 0,
    items: [],
    checkoutStatus: null
})

// getters
const getters = {
    getName: (state, getters) => { // eslint-disable-line no-unused-vars
        return state.name + '111';
    },
    cartProducts: (state, getters, rootState) => {
        return state.items.map(({ id, quantity }) => {
            const product = rootState.products.all.find(product => product.id === id)
            return {
                id: product.id,
                title: product.title,
                price: product.price,
                quantity
            }
        })
    },

    cartTotalPrice: (state, getters) => {
        return getters.cartProducts.reduce((total, product) => {
            return total + product.price * product.quantity
        }, 0)
    }
}

// actions
const actions = {
    //async checkout({ commit, state }, products) {
    //    const savedCartItems = [...state.items]
    //    commit('setCheckoutStatus', null)
    //    // empty cart
    //    commit('setCartItems', { items: [] })
    //    try {
    //        await shop.buyProducts(products)
    //        commit('setCheckoutStatus', 'successful')
    //    } catch (e) {
    //        console.error(e)
    //        commit('setCheckoutStatus', 'failed')
    //        // rollback to the cart saved before sending the request
    //        commit('setCartItems', { items: savedCartItems })
    //    }
    //},

    //addProductToCart({ state, commit }, product) {
    //    commit('setCheckoutStatus', null)
    //    if (product.inventory > 0) {
    //        const cartItem = state.items.find(item => item.id === product.id)
    //        if (!cartItem) {
    //            commit('pushProductToCart', { id: product.id })
    //        } else {
    //            commit('incrementItemQuantity', cartItem)
    //        }
    //        // remove 1 item from stock
    //        commit('products/decrementProductInventory', { id: product.id }, { root: true })
    //    }
    //}

    addName({ state, commit }) {
        commit('setName', 'sssss')

    }

}

// mutations
const mutations = {
    pushProductToCart(state, { id }) {
        state.items.push({
            id,
            quantity: 1
        })
    },

    incrementItemQuantity(state, { id }) {
        const cartItem = state.items.find(item => item.id === id)
        cartItem.quantity++
    },

    setCartItems(state, { items }) {
        state.items = items
    },

    setCheckoutStatus(state, status) {
        state.checkoutStatus = status
    },

    setName(state, status) {
        state.name = status
    },
    SET_TYPE: (state, type) => {
        state.type = type;
        setItem('appType', type);
    },
    SET_ISPHONE: (state, IsPhone) => {
        state.IsPhone = IsPhone;
        setItem('IsPhone', IsPhone);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
