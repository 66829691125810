const getters = {
    appType: state => state.app.type,
    appLogin: state => state.user.appLogin,

    token: state => state.user.token,
    userInfo: state => state.user.userInfo[0],
    isLogin: state => state.user.userInfo.length,
    paper: state => state.topic.paper,
    grade: state => state.topic.grade,
    period: state => state.topic.period,

    answerListBase: state => state.topic.answerList,
    indexBase: state => state.topic.topicIndex,
    replayAnswerListBase: state => state.topic.replayAnswerList,

    studentInfo: state => state.topic.studentInfo,
    topicList: state => state.topic.topicList,
    classNameV: state => state.user.className,
    paperV: state => state.user.paper,
    gradeV: state => state.user.grade,
    IsGolBalV: state => state.user.IsGolBal,
    periodV: state => state.user.period,


    IsPhone: state => state.app.IsPhone,


}
export default getters
