<template>
    <div class="herader_bg">
        <div class="container dadi_flex" style="justify-content:space-between;">
            <img src="./images/abc_logo.png">
            <!--<img src="./images/readingIQ_logo.png">-->
        </div>
    </div>
    <el-config-provider :locale="locale">
        <!--<keep-alive v-if="$route.meta.keepAlive">
            <router-view></router-view>
        </keep-alive>
        <template v-if="$route.meta.keepAlive==false">

        </template>-->
        <router-view></router-view>
    </el-config-provider>

</template>
<style scoped>
    @import './assets/style.css';
</style>

<script>
    import { ElConfigProvider } from 'element-plus'
    import zhCn from 'element-plus/lib/locale/lang/zh-cn'
    import { mapGetters, mapState, mapActions } from 'vuex'

    export default {
        name: 'App',
        provide() {
            return {
            }
        },
        computed: {
            ...mapState({
                name1: state => state.app.name
            }),
            ...mapGetters('app', {
                name: 'getName'
            })
        },
        components: {
            ElConfigProvider
        },
        data() {
            return {
                locale: zhCn
            }
        },
        methods: {
            ...mapActions('user', [
                'addName'
            ]),
            checkout(products) {
                this.$store.dispatch('app/checkout', products)
            }
        },
        created() {
        },
        mounted() {
        }

    }
</script>
