import request from '@/utils/request'


//export function adminLogin(data) {
//    //"param": "{'phone':'13680175631','password':'175631','type':'Login'}
//    return request({
//        url: '/ABCMouseExam',
//        method: 'GET',
//        data
//    })
//}

export function apiLogin(data) {
    var param = '';
    if (data.userName == '13680175631') {
        param = { 'phone': data.userName, 'pk_Period': 47, 'password': data.Pwd, 'type': 'Login' };
    } else {
        param = { 'phone': data.userName, 'password': data.Pwd, 'type': 'LoginNew' };
    }


    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}



export function apiPeriod(IsGolBal) {
    var param = { 'IsGolBal': IsGolBal, 'type': 'GetPeriod' };
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}

export function apiClassInfo(data, pk_Period, IsGolBal) {
    var param = { 'pk_Kid': data.pk_Kid, 'pk_Period': pk_Period, 'isGolbal': IsGolBal, 'type': 'ClassInfo' };
    //    { "token_Phone": "13680175631",  "param": "{'pk_Kid':'283','pk_Period':'44','type':'ClassInfo'}"}
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}


export function apiExamType(data, pk_Period, IsGolBal) {
    var param = { 'isGolbal': IsGolBal, 'pk_Period': pk_Period, 'type': 'ExamType' };
    //   { "token_Phone": "13680175631",  "param": "{'pk_Kid':'283','pk_Period':'44','type':'StudentInfo'}"}
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}

export function apiLevels(data, pk_Period, ExamCode) {
    var param = { 'ExamCode': ExamCode, 'pk_Period': pk_Period, 'type': 'Levels' };
    //   { "token_Phone": "13680175631",  "param": "{'pk_Kid':'283','pk_Period':'44','type':'StudentInfo'}"}
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}



export function apiStudentInfo(data, pk_Period, className, IsGolBal) {
    var param = { 'pk_Kid': data.pk_Kid, 'pk_Period': pk_Period, 'isGolbal': IsGolBal, 'ClassName': className, 'type': 'StudentInfo' };
    //   { "token_Phone": "13680175631",  "param": "{'pk_Kid':'283','pk_Period':'44','type':'StudentInfo'}"}
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}



export function apiQZStudentInfo(TeacherId, beginDate, endDate, userName) {
    var param = { 'TeacherId': TeacherId, 'beginDate': beginDate, 'endDate': endDate, 'userName': userName, 'type': 'QZStudentInfo' };
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}
