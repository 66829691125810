import { createStore } from 'vuex'
import getters from './getters'

// 获得全部module
const modulesFiles = require.context('./modules', false, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})


//const store = createStore({ modules })

///**
// * 安装vuex
// * @param {*} app
// */
//export function installStore(app) {
//    app.use(store)
//}


export default createStore({
    modules, getters
})
