import Vue from 'vue'
import cryptoJs from 'crypto-js'
let keyOne = 'eGJSJSON'
let ivOne = '561290EFABCD3478';   //C# private static byte[] desIV = { 0x56, 0x12, 0x90, 0xEF, 0xAB, 0xCD, 0x34, 0x78 };


export function jiami(word) {
    let key = cryptoJs.enc.Utf8.parse(keyOne);
    var ivs = cryptoJs.enc.Hex.parse(ivOne);
    let enc = ''
    if (typeof word === 'string') {
        enc = cryptoJs.DES.encrypt(word, key, {
            iv: ivs,
            mode: cryptoJs.mode.CBC,
            padding: cryptoJs.pad.Pkcs7
        })
    } else if (typeof word === 'object') {
        let data = JSON.stringify(word)
        enc = cryptoJs.DES.encrypt(data,
            key, {
            iv: ivs,
            mode: cryptoJs.mode.CBC,
            padding: cryptoJs.pad.Pkcs7
        })
    }
    let encResult = enc.ciphertext.toString().toUpperCase();
    var oldHexStr = cryptoJs.enc.Hex.parse(encResult);
    // 将密文转为Base64的字符串
    var base64Str = cryptoJs.enc.Base64.stringify(oldHexStr);
    return base64Str.replace(/[?]/g, "`").replace(/[&]/g, "~").replace(/[ ]/g, "^").replace(/[+]/g, "$").replace(/[/]/g, "*");
}

export function jiemi(word) {
    word = word.replace(/[`]/g, "?").replace(/[~]/g, "&").replace(/[\^]/g, " ").replace(/[$]/g, "+").replace(/[*]/g, "/");
    var keyHex = cryptoJs.enc.Utf8.parse(keyOne);
    var ivs = cryptoJs.enc.Hex.parse(ivOne);
    var decrypted = cryptoJs.DES.decrypt({
        ciphertext: cryptoJs.enc.Base64.parse(word)
    }, keyHex, {
        iv: ivs,
        mode: cryptoJs.mode.CBC,
        padding: cryptoJs.pad.Pkcs7
    });
    let decData = cryptoJs.enc.Utf8.stringify(decrypted)
    return decData;
}
