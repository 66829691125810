module.exports = {

    title: 'Abcmouse测评',
    baseURL: 'https://localhost:44357/api/',
    URL: 'https://oa.dadikid.com',

    /**
     * @type {boolean} true | false
     * @description Whether fix the header
     */
    fixedHeader: false,

    /**
     * @type {boolean} true | false
     * @description Whether show the logo in sidebar
     */
    sidebarLogo: false,


    appKey: '165407380000009f',
    //sigurl: 'https://api.chivoxapp.com/tstest/zm/sig.php',
    sigurl: 'https://scmapi.dadikid.com/api/ABCMouseSig'
}
