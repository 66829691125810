import request from '@/utils/request'


export function apiTopicList(Levels, pk_Period, TypeId) {
    // { "token_Phone": "13680175631",  "param": "{'Levels':'二级','pk_Period':'44','TypeId':'A','type':'Subject'}"}
    var param = { 'Levels': Levels, 'pk_Period': pk_Period, 'Sub_Type': TypeId, 'type': 'Subject' };
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}

export function apiSaveTopic(data, studentInfo, answer, TypeId, pk_Period) {
    // {pk_User:44, pk_Kid:283, pk_StudentId:0,pk_Period:47, ExamResult:answer,Sub_Type:A,type: SaveData}
    var param = { 'pk_User': studentInfo.pk_User, 'pk_Kid': data.pk_Kid, 'pk_StudentId': studentInfo.pk_StudentId, 'pk_Period': pk_Period, ExamResult: answer, 'Sub_Type': TypeId, 'type': 'SaveData' };
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}


export function apiQZSaveTopic(phone, name, age, teacherid, answer) {
    // {pk_User:44, pk_Kid:283, pk_StudentId:0,pk_Period:47, ExamResult:answer,Sub_Type:A,type: SaveData}
    var param = { 'phone': phone, 'name': name, 'age': age, 'teacherid': teacherid, ExamResult: answer, 'qzname': '', 'qzcode': '', 'teachername': '', 'type': 'QZSaveData' };
    return request({
        url: '/ABCMouseExam',
        method: 'POST',
        data: {
            param: JSON.stringify(param)
        }
    })
}
