import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['login', 'loginOut', '/auth-redirect', 'qzf','dat'] // no redirect whitelist

let registerRouteFresh = true;
router.beforeEach(async (to, from, next) => {
    NProgress.start()

    const hasToken = getToken();
    if (hasToken) {
        if (whiteList.indexOf(to.name) !== -1) {
            next()
            NProgress.done()
        } else {
            const appLogin = store.getters.appLogin; //权限
            if (appLogin) {
                next()
            } else {
                const hasRoles = store.getters.isLogin; //登录
                if (hasRoles > 0) {
                    const appType = store.getters.appType;

                    if (appType == 'qinzifang') {
                        document.title = '入读级别评估'
                    } else {
                        document.title = getPageTitle(to.meta.title)
                    }


                    router.addRoute({
                        path: '/',
                        name: '首页',
                        meta: { title: '首页', keepAlive: false },
                        component: () => import('@/views/' + appType + '/Index')
                    });
                    router.addRoute({
                        path: '/q',
                        name: '题目',
                        meta: { title: '题目', keepAlive: false },
                        component: () => import('@/views/' + appType + '/question')
                    });

                    store.commit('user/SET_APPLOGIN', true);
                    next({ ...to, replace: true })
                } else {
                    next(`/login`)
                    NProgress.done()
                }
            }
            //const hasRoles = store.getters.isLogin;
            //if (hasRoles > 0) {
            //    next()
            //} else {
            //    next(`/login`)
            //    NProgress.done()
            //}

        }
    } else {
        if (whiteList.indexOf(to.name) !== -1) {
            next()
        } else {
            next(`/login`)
            NProgress.done()
        }
    }
})
router.afterEach(() => {
    NProgress.done()
})
