

function checkVal(val) {
  if (typeof val === 'object') {
    val = JSON.stringify(val);
  }
  return val;
}

function checkKey(key) {
  if (typeof key != 'string') {
    console.log('the key is not string!');
    return false;
  }
  return true;
};

function checkKeyVal(keyArr, valArr) {
  if (keyArr.length != valArr.length) {
    console.log('The length of keyArr and valArr need eq.');
    return false;
  }
  return true;
};

function isJsonStr(str) {
  try {
    var obj = JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

function checkArr(arr) {
  return arr instanceof Array;
}


export function setList(keys, values) {
  if (checkKeyVal(keys, values)) {
    for (var i = 0, n = keys.length; i < n; i++) {
      setItem(keys[i], values[i]);
    }
  }
}

export function setArr(prex, arr) {
  if (checkArr(arr)) {
    for (var i = 0, n = arr.length; i < n; i++) {
      var key = prex + '_' + i;
      setItem(key, arr[i]);
    }
  } else {
    setItem(prex, arr);
  }
};

export function sortKeys(keys) {
  if (!keys instanceof Array) {
    return false;
  }
  keys.map(function (a, b) {
    a = Number(a.match(/_\d+$/)[0].substr(1));
    b = Number(b.match(/_\d+$/)[0].substr(1));
    return a - b;
  });
  return keys;
};

export function filterKeysByReg(keys, prex) {
  var arr = [];
  var reg = new RegExp(prex);
  keys.forEach(function (key) {
    if (reg.test(key)) {
      arr.push(key);
    }
  });
  return arr;
};

export function getItemsByKeys(keys) {
  var arr = [];
  var _this = this;
  keys.forEach(function (key) {
    arr.push(_getItem(key));
  });
  return arr;
};



export function clearAll() {
  window.localStorage.clear();
};

export function removeItem(key) {
  window.localStorage.removeItem(key);
};

export function getItem(key) {
  var str = window.localStorage.getItem(key);
  if (isJsonStr(str)) {
    str = JSON.parse(window.localStorage.getItem(key));
  }
  return str;
};

export function setItem(key, val) {
  if (checkKey(key)) {
    val = checkVal(val);
    window.localStorage.setItem(key, val);
  }
};


export function getArr(prex) {
  var _this = this;
  var arr = [];
  var keys = Object.keys(window.localStorage);
  keys = _filterKeysByReg(keys, prex);
  arr = _getItemsByKeys(keys);
  return arr;
}; 
