import axios from 'axios'
import qs from 'qs';
import store from '@/store'

let url = '';
if (process.env.NODE_ENV === 'production') {
    url = 'https://gjsapi.dadikid.com/api/';
} else {
    url = 'https://localhost:44357/api/';
}
url = 'https://scmapi.dadikid.com/api/';
// create an axios instance
const service = axios.create({
    baseURL: url,//process.env.VUE_APP_BASE_API, // url = base url + request url
    //withCredentials: false, // send cookies when cross-domain requests
    timeout: 50000, // request timeout
    //credentials: 'omit',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }

})
// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            //console.log(store.getters.token)
            //config.headers.common['Token'] = encodeURI(store.getters.token);
        }
        let phone = '13680175631';
        if (config.method === 'get') {
            config.params = {
                token_Phone: phone,
                param: JSON.stringify(config.data),
                ...config.params
            }
        }
        if (config.method === 'post') {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
            config.data = qs.stringify({
                token_Phone: phone,
                ...config.data
            })
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.result !== 'success') {
            return Promise.reject(res.msg)
        } else {
            return res
        }

    },
    error => {
        this.$message.error(error.msg);
        return Promise.reject(error)
    }
)

export default service
