import { apiTopicList, apiSaveTopic, apiQZSaveTopic } from '@/api/topic'
import { clearAll, getItem, setItem } from '@/utils/LsyLocalStorage'

// initial state
// shape: [{ id, quantity }]
const state = () => ({
    topicList: getItem('topicList') || [],
    studentInfo: getItem('studentInfo') || {},
    paper: getItem('paper') || {},
    grade: getItem('grade') || '',
    period: getItem('period') || '',
    topicIndex: getItem('topicIndex') || 0,
    answerList: getItem('answerList') || {},
    replayAnswerList: getItem('replayAnswerList') || {},

    //topicList: [],
    //studentInfo: {},
    //paper: {},
    //grade:  ''
})

// getters
const getters = {

}

// actions
const actions = {
    async SaveTopic({ rootState, commit, state }, d) {
        return new Promise((resolve, reject) => {
            var data = rootState.user.userInfo[0];
            var studentInfo = state.studentInfo;
            var paper = state.paper.id;
            apiSaveTopic(data, studentInfo, d.answer, paper, d.pk_Period).then(response => {
                resolve(true);
            }).catch(error => {
                reject(error)
            })
        });

    },
    async getTopicList({ rootState, commit, state }, data) {
        commit('SET_TOPICINDEX', null)
        commit('SET_ANSWERLIST', null)
        commit('SET_REPLAYANSWERLIST', null)


        //缓存幼生，题目，等级
        commit('SET_STUDENTINFO', data.studentInfo)
        commit('SET_PAPER', data.paper)
        commit('SET_GRADE', data.grade)
        commit('SET_PERIOD', data.pk_Period)

        var pk_Period = data.pk_Period;//rootState.user.userInfo[0].pk_Period;
        return new Promise((resolve, reject) => {
            apiTopicList(data.grade, pk_Period, data.paper.id).then(response => {
                var list = response.data;
                commit('SET_TOPICLIST', list)
                resolve(true);
            }).catch(error => {
                commit('SET_TOPICLIST', [])
                reject(error)
            })
        });
    },
    async GetSubjectList({ rootState, commit, state }, data) {
        commit('SET_TOPICINDEX', null)
        commit('SET_ANSWERLIST', null)
        commit('SET_REPLAYANSWERLIST', null)


        //缓存幼生，题目，等级
        commit('SET_STUDENTINFO', data.studentInfo)
        var pk_Period = data.pk_Period;
        return new Promise((resolve, reject) => {
            apiTopicList(data.grade, pk_Period, 'Q').then(response => {
                var list = response.data;
                commit('SET_TOPICLIST', list)
                resolve(true);
            }).catch(error => {
                commit('SET_TOPICLIST', [])
                reject(error)
            })
        });
    },
    async SaveQZTopic({ rootState, commit, state }, answer) {
        return new Promise((resolve, reject) => {
            var teacherid = rootState.user.userInfo[0].userId;
            var studentInfo = state.studentInfo;
            apiQZSaveTopic(studentInfo.phone, studentInfo.username, studentInfo.age, teacherid, answer).then(response => {
                resolve(response);
            }).catch(error => {
                reject(error)
            })
        });

    },

}

// mutations
const mutations = {
    SET_TOPICLIST: (state, topicList) => {
        state.topicList = topicList;
        setItem('topicList', topicList);
    },

    SET_STUDENTINFO: (state, studentInfo) => {
        state.studentInfo = studentInfo;
        setItem('studentInfo', studentInfo);
    },
    SET_PAPER: (state, paper) => {
        state.paper = paper;
        setItem('paper', paper);
    },
    SET_GRADE: (state, grade) => {
        state.grade = grade;
        setItem('grade', grade);
    },
    SET_PERIOD: (state, period) => {
        state.period = period;
        setItem('period', period);
    },
    SET_TOPICINDEX: (state, topicIndex) => {
        state.topicIndex = topicIndex;
        setItem('topicIndex', topicIndex);
    },
    SET_ANSWERLIST: (state, answerList) => {
        state.answerList = answerList;
        setItem('answerList', answerList);
    },
    SET_REPLAYANSWERLIST: (state, replayAnswerList) => {
        state.replayAnswerList = replayAnswerList;
        setItem('replayAnswerList', replayAnswerList);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
} 
